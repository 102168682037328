import React, { useState, useEffect } from 'react';
import AudioUpload from './components/AudioUpload';
import logo from './logo.svg';
import axios from 'axios';
import './App.css';

function App() {
  const [currentTime, setCurrentTime] = useState(0);
  const getTime = async () => {
    await axios.get(process.env.REACT_APP_TIME_API)
        .then(response => {
            setCurrentTime(response.data.time)
      }).catch((err) => {
            console.log(err)
      });
  }

  useEffect(() => {
    getTime();
  }, [])

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        <p>The current time is {currentTime}.</p>
        <AudioUpload />
      </header>
    </div>
  );
}

export default App;
