import React, { useState } from 'react';
import axios from 'axios';

const AudioUpload = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');
  const [downloadLink, setDownloadLink] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setDownloadLink(null);  // Reset the download link when a new file is selected
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setUploadStatus('Please select an audio file first.');
      return;
    }

    const formData = new FormData();
    formData.append('audio', selectedFile);

    try {
      setUploadStatus('Uploading...');
      const response = await axios.post(process.env.REACT_APP_AUDIO_API, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        responseType: 'blob'  // Ensure the response is handled as a blob for file downloads
      });

      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      setDownloadLink(fileURL);

      setUploadStatus('Upload successful. Download the processed file below:');
      // Logic to handle file download (optional) using response data
    } catch (error) {
      setUploadStatus('Upload failed. Please try again.');
      console.error('Error uploading file:', error);
    }
  };

  return (
    <div>
      <h1>Upload Audio File</h1>
      <input type="file" accept="audio/*" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload</button>
      <p>{uploadStatus}</p>

      {downloadLink && (
        <div>
          <a href={downloadLink} download="processed_audio.wav">
            Download Processed File
          </a>
        </div>
      )}

    </div>
  );
};

export default AudioUpload;
